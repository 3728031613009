import React from 'react';
import {Button, Container, Nav, Navbar, NavDropdown, Modal} from "react-bootstrap";
import logo from '../assets/logo/Ouveme_Logo_Dark.png';

const Header = () => {
    return (
       <header className="App-header">
            <img src={logo} alt="logo" className="App-logo" />
       </header>
    );
};

export default Header;

