import logo from './logo.svg';
import './App.css';
import Header from './components/header';
import Presentation from './components/presentation';

function App() {
  return (
    <div className="App">
      <Header />
      <Presentation />  

    </div>
  );
}

export default App;
