import React, {useState} from 'react';
import {collection, addDoc} from 'firebase/firestore';
import { db } from '../firebaseConfig';
import logoComp from '../assets/logo/Ouveme_Logo_Comp.png';

const Presentation = () => {

    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [message, setMessage] = useState('');
  

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const docRef = await addDoc(collection(db, 'NotifyUsers'), {
                email,
                name
            });
            console.log('Document written with ID: ', docRef.id);
            setMessage('Obrigado por se inscrever! Em breve receberá novidades.');
        }
            catch (e) {
                console.error('Error adding document: ', e);
                setMessage('Sorry, something went wrong. Please try again.');
        }
    };

    return (
        <div className='landing-container'>

           

                <h1 className='coming-soon'>Brevemente ...</h1>     

                <form className='email-form' onSubmit={handleSubmit}>

                    <input
                        type='text'
                        placeholder='Nome'
                        className='email-input'
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                    />

                    <input
                        type='email'
                        placeholder='Email'
                        className='email-input'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />

                    <button type='submit' className='submit-button'>Receber novidades</button>
                </form>
                {message && <p className='message'>{message}</p>}

            </div>

            
      
    );
};

export default Presentation;